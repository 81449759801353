.element-type-button-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.element-type-button {
  border: none;
  border-radius: 10px;
  background-color: rgba(253, 236, 239, 0.5);
  padding: 10px;
}

.element-type-button:hover {
  filter: brightness(85%);
  transition: filter 0.3s;
}

.element-type-button:hover,
.element-type-button.selected {
  border: 1px solid #f75201;
  cursor: pointer;
}


@media only screen and (min-width: 1024px) {
  .element-type-button-group {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}


/* Primary Button */

.MuiButton-contained:not(.Mui-disabled) {
  background-color: var(--orange) !important;
  color: var(--buttonTextColor) !important;
}

.MuiButton-outlined:not(.Mui-disabled, .MuiButton-outlinedWarning) {
    border-color: var(--orange) !important;
    color: var(--orange) !important;
}
.MuiButton-outlined:hover {
  background-color: #f7520114!important;
}

.MuiButton-text {
  color: var(--orange) !important;
}

.MuiButton-text:hover {
  background-color: #4f4f4f14!important;
}

/* Checkbox */
.Mui-checked {
    color: var(--orange) !important;
}

/* Menu Item Selected */
.MuiListItemButton-root.Mui-selected {
  background-color: #08031d21 !important;
}

/* Tab Item */
.MuiTab-root {
  text-transform: none !important;
  font-size: 16px !important;
}

.MuiTab-root.Mui-selected {
  color: #000 !important;
}

.MuiTabs-indicator {
  background-color: var(--orange) !important;
}

.MuiMenu-button {
  display: flex !important;
  justify-content: space-between !important;
  width: '100%' !important;
  gap: 10% !important;
  text-transform: unset !important;
}

.MuiButton-outlinedWarning {
  background-color: #FCCBB2 !important;
  color: #CC0000 !important;
}

.MuiDialogActions-root {
  display: flex !important;
  position: relative !important;
  justify-content: center !important;
}

.MuiAmountIconButton{
  color: var(--orange) !important;
}

.MuiAmountIconButtonDisabled{
  color: grey !important;
}

.MuiListItemButton-root.onboarding {
  color: var(--orange);
  background-color: #f753002b;
}

.MuiListItemButton-root.onboarding .MuiListItemIcon-root {
  color: var(--orange);
}

.css-1m1zhph-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: white !important;
}

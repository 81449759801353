/* backdrop color scheme */
.ui-slider.backdrop .slick-dots li button::before{
    color: white;
}

.ui-slider.backdrop .slick-dots li button::before{
    opacity: 0.75;
}

/* dialog color scheme */
.ui-slider.dialog .slick-dots li button::before{
    color: black;
}

.ui-slider.dialog .slick-next:before,
.ui-slider.dialog .slick-prev:before{
    color: black;
    opacity: 0.6;
}

.ui-slider.dialog .slick-next:hover:before,
.ui-slider.dialog .slick-prev:hover:before{
    color: black;
    opacity: 1;
}

.ui-slider.dialog .slick-dots li button::before{
    opacity: 0.6;
}


/* common color scheme */
.ui-slider .slick-dots li.slick-active button::before{
    opacity: 1;
}


.ui-slider img {
    max-width: 75vw;
    height: auto;
    object-fit: contain;
}

.ui-slider.dialog .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 0 20px;
}

@media only screen and (min-width: 1024px) {

    .ui-slider img {
        width: auto;
        max-height: 65vh;
        object-fit: contain;
    }
}

.free-subscription-modal .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 40px 0px;
}


.free-subscription-modal .content .description {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.4em;
    font-size: 14px;
    white-space: pre-line;
}

.free-subscription-modal .content img {
    max-width: 100%;
    max-height: 24vh;
    margin: 0 auto;
}

.free-subscription-modal .content .alert,
.free-subscription-modal .content .textfield,
.free-subscription-modal .content .ui-form-section {
    width: 100%;
}

.free-subscription-modal .footer {
    text-align: center;
    width: 100%;
    padding-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
    .free-subscription-modal .content .alert,
    .free-subscription-modal .content .textfield,
    .free-subscription-modal .content .ui-form-section {
        width: 40%;
    }
    
}
.ui-split-button .ui-button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0px;
    box-shadow: none;
}

.ui-split-button .ui-button.menu-button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
    border-right: 1px solid;
    box-shadow: none;
}
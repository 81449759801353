.swipeable-drawer .points-chip {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 68px;
    border: 1px solid rgba(208, 213, 221, 0.58);
    background: var(--secondaryBackgroundColor);
    justify-content: space-around;
    margin: 16px 16px;
    gap: 4px;
    color: var(--primaryTextColor);
}
.fullwidth-card {
    background-color: #ffff;
    color: #13171a;
    padding: 30px;
    border-radius: var(--radius);
    margin: 30px 0 30px 0;
    display: grid;
    grid-template-columns: 300px auto;
    gap: 12px;
}

.fullwidth-card-preview {
    background-color: #ffff;
    border-radius: var(--radius);
    padding: 30px;
}

.fullwidth-card-image-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.fullwidth-card-image{
    width: 300px;
    border-radius: var(--radius);
}

.fullwidth-card-header {
    grid-column: 2; 
    grid-row: 1;
}

.tickets-grid .fullwidth-card-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
}

.fullwidth-card-sub-header-container {
    display: flex;
    justify-content: space-between;
}

.fullwidth-card-price {
    font-size: x-large;
    display: flex;
    align-items: center;
    gap: 20px;
}

.fullwidth-card-content {
    grid-column: 2; 
    grid-row: 2;
}

.fullwidth-card-footer {
    grid-column: 2; 
    grid-row: 3;
}

.fullwidth-card-button {
    width: 200px;
}

.fullwidth-card-content .button-group {
    display: flex;
    gap: 4px;
    width: 100%;
    align-items: center;
}

@media only screen and (max-width: 1024px) {

    .fullwidth-card {
        grid-template-columns: 1fr;
    }
    
    .fullwidth-card-image-container {
        grid-column: 1; 
        grid-row: 1;
    }
  
    .fullwidth-card-image {
        max-width: 100%;
    }

    .fullwidth-card-header {
        grid-column: 1; 
        grid-row: 2;
    }

    .fullwidth-card-content {
        grid-column: 1; 
        grid-row: 3;
    }

    .fullwidth-card-content .button-group {
        flex-direction: column;
    }
    
    .fullwidth-card-footer {
        grid-column: 1; 
        grid-row: 4;
    }

    .fullwidth-card-sub-header-container {
        flex-direction: column; 
        gap: 30px;
    }
      
    .fullwidth-card-price {
        gap: 10px;
    }
  
    .fullwidth-card-button {
        width: 100%;
    }
}
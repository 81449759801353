.profile-master {
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    background-color: var(--primaryBackgroundColor);
}

.profile-master .profile_content {
    width: 100%;
    background-color: var(--secondaryBackgroundColor);
}

.profile-master .profile_content::-webkit-scrollbar {
    display: none;
}

.profile-master .profile_content__body{
    padding: 20px 6px;
    margin-bottom: 52px;
    min-height: calc(100vh - 104px);
}

@media screen and (min-width: 1024px) {
    .profile-master {
        flex-direction: row;
        margin-top: 0px;
    }

    .profile-master .profile_content__body{
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px 40px 100px 40px;
        min-height: calc(100% - 244px);
    }

    .profile-master .profile_content {
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.simple-table {
    background-color: white;
    border-radius: var(--radius);
}

.simple-table_head {
    color: var(--orange);
}

.simple-table_head-row,
.simple-table_row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--orange);
}

.simple-table_row:last-of-type {
    border-bottom: none;
}

.simple-table_head-cell,
.simple-table_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 12px;
    word-break: break-all;
}

.simple-table_cell > div {
    display: flex;
    gap: 10px;
    justify-content: baseline;
    align-items: center;
    width: 100%;
}
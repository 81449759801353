.ui-media-gallery {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
}

.ui-media-gallery .attachments {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.ui-media-gallery .attachments .post-images,
.ui-media-gallery .attachments .product-images {
    display: grid;
    gap: 8px;
    align-items: center;
    justify-items: center;
}

.ui-media-gallery .attachments .post-images .last-image-container,
.ui-media-gallery .attachments .product-images .last-image-container {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.ui-media-gallery .attachments .post-images .last-image-container p, 
.ui-media-gallery .attachments .product-images .last-image-container p{
    color: white;
    font-size: 32px;
    margin: 0;
    transform: translate(-50%,-50%);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
}

.ui-media-gallery .attachments .post-images .last-image-container .image.last,
.ui-media-gallery .attachments .product-images .last-image-container .image.last {
  background-color: rgba(0, 0, 0, 0.5);
  filter: brightness(55%);
  transform: translate(-50%,-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
}

.ui-media-gallery .video {
    border-radius: 7px;
    max-width: 100%;
    max-height: 460px;
    object-fit: contain;
}

.ui-media-gallery .attachments .post-images .image,
.ui-media-gallery .attachments .product-images .image {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    height: 100px;
}

.ui-media-gallery .attachments .post-images .image:first-child,
.ui-media-gallery .attachments .product-images .image:first-child {
    grid-column: span 3;
    height: auto;
}
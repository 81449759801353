.connected-accounts-container{
  padding: var(--mobilePadding);
}

.connected-account.grid {
  border-radius: var(--radius);
  background-color: white;
  padding: var(--mobilePadding);
  grid-template-columns: 40px 240px;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.connected-account.grid > img {
  width: 100%;
}

.connected-account .username {
  display: flex;
  align-items: center;
  gap: 10px;
  grid-column: 1 / span 2;
}

@media only screen and (min-width: 1024px) {
  .connected-account.grid { 
    grid-template-columns: 40px 1fr 160px;
  }

  .connected-account .username {
    justify-content: space-between;
    grid-column: unset;
  }
}
.ui-title {
    margin: 0;
    color: var(--primaryTextColor);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: -0.48px;
}

.ui-title.bold {
    font-family: 'VTFRedzone', sans-serif;
    font-size: 38px;
    font-weight: 600;
    text-align: center;
}

.ui-subtitle {
    margin: 0;
    color: var(--primaryTextColor);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4em;
}

.ui-subtitle.ui-small-text {
    font-size: 16px;
}

.ui-struck-text {
    text-decoration: line-through;
}

.ui-description {
    margin: 0;
    color: var(--primaryTextColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4em;
}

.ui-label {
    margin: 0;
    color: var(--primaryTextColor);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
}

.ui-label.ui-small-text {
    font-size: 10px;
}

.ui-highlighted-text {
    color: var(--highlightColor);
}

.ui-secondary-text {
    color: var(--secondaryTextColor);
}

.ui-large-text {
    font-size: larger;
}

.ui-small-text {
    font-size: small;
}

.ui-bold-text {
    font-weight: bolder;
}

.ui-padded-text {
    padding: 0px 10px 10px;
}

.ui-mobile-hidden {
    display: none;
}

.ui-pre-wrap {
    white-space: pre-wrap;
}

.ui-red-text {
    color: red;
}

@media only screen and (min-width: 1024px) {
    .ui-mobile-hidden {
        display: block;
    }
}

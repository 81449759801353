.ui-date {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    border-radius: 6px;
    border: 1px solid var(--borderColor);
    background-color: var(--secondaryBackgroundColor);
}

.ui-date .day {
    color: var(--primaryTextColor);
    font-size: 20px;
    font-weight: 600;
}

.ui-date .month {
    color: var(--secondaryTextColor);
    font-size: 14px;
    font-weight: 400;
}
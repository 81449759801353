.ui-content-menu{
    display: flex;
    gap: 10%;
    justify-content: center;
    align-items: center;
    height: 52px;
    background-color: var(--primaryBackgroundColor);

    padding: 0 20px;

    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.2);
}

.ui-content-menu::-webkit-scrollbar {
    display: none;
}

.ui-content-menu .nav-link {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    color: #667085;
}

.ui-content-menu .nav-link.is-active .nav-item {
    color: var(--highlightColor);
}

.ui-content-menu .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--secondaryTextColor);
}

.ui-content-menu .menu-button {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .ui-content-menu{
        justify-content: center;
        gap: 0px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        position: sticky;
        top: 0;
        height: 72px;
        z-index: 1000;
    }

    .ui-content-menu .nav-link {
        padding: 0 0.75%;
    }

    .ui-content-menu .nav-link.is-active {
        border-bottom: 2px solid var(--highlightColor);
    }

    .ui-content-menu .nav-item {
        flex-direction: row;
        gap: 8px;
        font-size: 18px;
    }

    .ui-content-menu .nav-link.is-active .nav-item {
        color: var(--highlightColor) !important;
    }

    .ui-content-menu .menu-button {
        display: block;
        position: absolute;
        right: 10px;
        color: var(--primaryTextColor);
    }
}

@media only screen and (min-width: 1200px) {
    .ui-content-menu{
        gap: 20px;
    }

    .ui-content-menu .nav-link {
        padding: 0 20px;
    }
}

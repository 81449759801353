/* Pricing */
.other-frequencies {
  cursor: pointer;
  margin-top: 10px !important;
}

.other-frequencies span {
  color: var(--orange);
  font-weight: 600;
  font-size: 1.4rem;
}


.ui-page-element {
    background-color: var(--primaryBackgroundColor);
    width: 100%;
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-radius: var(--radius);
    font-size: 14px;
    color: var(--primaryTextColor);
}

.ui-page-element.calendar {
    padding: 0px;
    border-radius: 0px;
    background-color: transparent;
}

.ui-page-element.calendar > *:not(.management-tools) {
    width: 100%;
}

.ui-page-element .ui-calendar-empty {
    background-color: var(--primaryBackgroundColor);
    border-radius: var(--radius);
    padding: 24px;
    text-align: center;
    color: var(--textColorSecondary);
    font-size: 16px;
    border: 1px dashed var(--borderColor);
    margin: 16px 0;
    box-sizing: border-box;
}

.ui-page-element .ui-calendar-empty span {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    opacity: 0.6;
}

.ui-page-element .list-item-button:hover {
    background-color: transparent;
}

.ui-page-element .list-item-button {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
}

.ui-page-element .list-item-button.locked {
    height: 100%;
    grid-template-columns: auto 1fr auto;
    height: 100%;
}

.ui-page-element .list-item-button .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui-page-element .list-item-button .image-container .image {
    height: 44px;
    max-width: 44px;
    border-radius: var(--radius);
}

.ui-page-element .list-item-button .icon {
    height: 26px;
}

.ui-social-element svg {
    height: 26px;
    fill: var(--highlightColor);
}

.ui-social-element:hover {
    cursor: pointer;
}

/* link */

.ui-page-element.link {
    border: 1px solid var(--primaryBackgroundColor);
}

.ui-page-element.link:hover {
    border: 1px solid var(--highlightColor);
}

/* title */

.ui-page-element.title {
    background-color: transparent;
    margin: 20px 0px 20px 0px;
}

.ui-page-element.title .title * {
    font-weight: bolder;
    font-size: 20px;
}

.ui-page-element.text .text {
    text-align: justify;
}

/* spotify_embed */
/* youtube_embed */

.ui-page-element.spotify_embed.profile,
.ui-page-element.youtube_embed.profile,
.ui-page-element.soundcloud_embed.profile {
    display: block;
    width: 100%;
    height: auto;
    background-color: transparent;
    padding: 0px;
}

.ui-page-element.youtube_embed .video-container {
    height: 0;
    padding-top: 25px;
    padding-bottom: 56.34%;
    position: relative;
    overflow: hidden;
    border-radius: var(--radius);
}

.ui-page-element.youtube_embed .video-container iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
    outline: none;
}

.ui-page-element .soundlcoud-container iframe {
    border: none;
}

.ui-page-element .management-tools {
    display: flex;
    align-items: center;
}

.ui-page-element .management-tools .clicked-count {
    font-weight: bolder;
    font-size: 16px;
}

.about-content {
  width: 100%;
}

.about-content .grid-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;
}

.about-content .grid-calendar .grid {
  grid-template-columns: 1fr;
  gap: 18px;
}

.about-content > * {
  margin-bottom: 20px;
}

.about-content .ui-subtitle {
  color: var(--primaryTextColor);
  filter: brightness(115%);

  font-size: 18px;
  margin-bottom: 16px;
}

.about-content .description {
  color: var(--primaryTextColor);
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4em;
  white-space: pre-line;
}

.about-content .stats .grid {
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.about-content .events .grid {
  grid-gap: 8px;
}

.about-content .social-elements {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px;
  flex-wrap: wrap;
}

.about-content .create-free-account {
  margin: 100px auto 20px;
  font-size: 14px;
}

.about-content .create-free-account span {
  padding-left: 10px;
  font-size: 22px;
}

@media screen and (min-width: 1024px) {
  
  .about-content .grid-calendar .grid {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 18px;
  }
}

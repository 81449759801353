.stores-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.stores-content .button-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: center;
    align-self: flex-end;
}

.stores-content .grid {
    grid-template-columns: 1fr;
    gap: 18px;
}

.stores-content .ui-card .footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.stores-content .ui-card .footer .application-fee-disclaimer {
    text-align: center;
}

.stores-content .button-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 24px;
}

.stores-content .button-content.centered {
    justify-content: center;
}

.stores-content .button-content > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.stores-content .tags {
    color: var(--primaryTextColor);
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.redeem-modal-description {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.redeem-modal-description .redeem-criteria{
    display: flex;
    gap: 16px;
}

@media screen and (min-width: 1024px) {
  
    .stores-content .grid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;
    }
    
}

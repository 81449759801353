:root {
  --viewfinder-border: 4px solid #F75201;
}

.viewfinder {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.viewfinder-corner {
  position: absolute;
  width: 40px;
  height: 40px;
}

.viewfinder-corner--top-left {
  top: 0;
  left: 0;
  border-top: var(--viewfinder-border);
  border-left: var(--viewfinder-border);
}

.viewfinder-corner--top-right {
  top: 0;
  right: 0;
  border-top: var(--viewfinder-border);
  border-right: var(--viewfinder-border);
}

.viewfinder-corner--bottom-left {
  bottom: 0;
  left: 0;
  border-bottom: var(--viewfinder-border);
  border-left: var(--viewfinder-border);
}

.viewfinder-corner--bottom-right {
  bottom: 0;
  right: 0;
  border-bottom: var(--viewfinder-border);
  border-right: var(--viewfinder-border);
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
  animation: fadeIn 1s ease-in-out;
}

.loading-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  animation: fadeIn 1s ease-in-out;
  text-align: center;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
} 
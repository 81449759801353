.posts-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 0 auto;
}

.posts-content .floating-button{
    margin: 0px;
    top: auto;
    right: 50px !important;
    bottom: 70px;
    left: auto;
    position: fixed !important;
    background-color: var(--highlightColor) !important;
}
.ui-calendar-compact {
    /* display: flex;
    flex-direction: column;
    gap: 12px; */
    background-color: var(--primaryBackgroundColor);
    border-radius: var(--radius);
    padding: 12px;
}

.ui-event-compact {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 10px;
    border-radius: var(--radius);
    border: 1px solid var(--primaryBackgroundColor);
}

.ui-event-compact:hover {
    border: 1px solid var(--highlightColor);
    cursor: pointer;
}

.ui-event-compact .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    width: 100%;
}

.ui-event-compact .details .cta {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ui-event-compact .time {
    color: var(--gray-500, #667085);
    font-size: 12px;
}

.ui-event-compact .ui-button {
    margin: 0;
}

.ui-calendar-extended {
    display: grid;
    grid-auto-rows: 1fr;
    gap: 12px;
}

.ui-event-extended {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    grid-gap: 16px;
    gap: 16px;
    border-radius: var(--radius);
    background-color: var(--primaryBackgroundColor);
    flex-direction: row;
    border: 1px solid var(--primaryBackgroundColor);
}

.ui-event-extended:hover {
    border: 1px solid var(--highlightColor);
}

.ui-event-extended .event-image {
    width: 32%;
    max-width: 250px;
    min-width: 100px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.ui-event-extended .event-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ui-event-extended .details {
    flex: 1;
    display: grid;
    gap: 3px;
}

.ui-event-extended .date-container {
    padding-bottom: 2px;
}

.ui-event-extended .price {
    color: var(--highlightColor);
}

@media only screen and (min-width: 1024px) {
  .ui-calendar-extended {
    grid-template-columns: 1fr 1fr;
  }
}
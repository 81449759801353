.ui-post-card {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    background-color: var(--primaryBackgroundColor);
    width: 100%;
}

.ui-post-card.locked .toastui-editor-contents {
    mask: linear-gradient(to bottom, 
        rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%, 
        rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
    ) 100% 50% / 100% 100% repeat-x;
}

.ui-post-card .paywall {
    display: grid;
    gap: 12px;
    border-radius: 7px;
    padding: 24px;
}

.ui-post-card .paywall > button {
    justify-self: center;
}

.ui-post-card .paywall > ul {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.ui-post-card .paywall > ul > li {
    list-style: none;
}

.ui-post-card .paywall > div {
    display: flex;
    justify-content: center;
    align-items: center; 
    gap: 12px;
}

.ui-post-card .tags {
    padding-top: 20px;
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.ui-post-card .tags > p {
    margin: 0 !important;
}

.ui-post-card img {
    max-width: 100%;
}

.ui-post-card .spotify-embed-iframe{
    border: none;
}

.ui-post-card .embeded-video {
    height: 0;
    padding-top: 25px;
    padding-bottom: 56.34%;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
  }

.ui-post-card .embeded-video.vimeo {
    padding-top: 0;
}

.ui-post-card .embeded-video iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
    outline: none;
    padding: 12px 0px;
}

.ui-post-card .embed .soundcloud-embed-iframe {
    border: none;
}

.ui-post-card .image.banner {
    width: 100%;
    height: auto;
    cursor: default;
}

.ui-post-card .footer {
    width: 100%;
}

.ui-post-card .footer * {
    background-color: var(--primaryBackgroundColor);
}

.ui-post-card .footer .buttons {
    width: 100%;
    display: flex;
    gap: 12px;
    cursor: default;
}

.ui-post-card .footer .buttons {
    width: 100%;
    display: flex;
    gap: 12px;
    cursor: default;
}

.ui-post-card .existing-comment {
    cursor: default;
    width: calc(100% - 26px);
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    align-items: center;
    position: relative;
    padding: 16px 12px;
    border-top: 1px solid #e2e2e2;
}

.ui-post-card .existing-comment .details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: calc(100% - 48px);
}

.ui-post-card .existing-comment .header {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.ui-post-card .existing-comment .delete-button {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);;
    opacity: 0;
    transition: opacity 0.5s;
}

.ui-post-card .existing-comment:hover .delete-button {
    opacity: 1;
}

.ui-post-card .new-comment {
    width: calc(100% - 26px);
    display: flex;
    padding: 16px 12px;
    border-top: 1px solid var(--highlightColor);
}

.ui-post-card .new-comment .text-area {
    resize: none;
    border: none;
    outline: none !important;
    width: 100%;
    color: var(--primaryTextColor);
}

.ui-post-card .new-comment .text-area::placeholder {
    line-height: 3;
}

.ui-post-card .new-comment:has(.text-area:focus) .text-area::placeholder {
    opacity: 0;
    transition: opacity 0.2s;
}

.ui-post-card .title {
    line-height: 1.6em;
    margin-top: 1.6em;
    margin-bottom: 10px;
}

@media only screen and (min-width: 1024px) {
    .ui-post-card .embeded-video {
        padding-bottom: 67.5%;
    }

    .ui-post-card .footer .buttons {
        gap: 16px;
    }
}

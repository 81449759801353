.ui-profile-images {
    width: 100%;
}

.ui-profile-images .profile-pic.mobile {
    margin: -70px auto 0;
}

.ui-profile-images .profile-pic {
    margin: -95px auto 0;
}
.toastui-editor-contents *:not(table) {
    line-height: 1.5em;
    font-family: var(--fontFamily);
    color: var(--primaryTextColor);
}

.toastui-editor-contents .placeholder {
    font-size: larger;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
    margin-top: 1.6em;
    margin-bottom: 10px;
    border: none;
    text-align: justify;
    word-break: break-word;
}

.toastui-editor-contents h1 {
    font-size: 22px;
}

.toastui-editor-contents h2 {
    font-size: 20px;
}

.toastui-editor-contents h3 {
    font-size: 18px;
}

.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
    font-size: 16px;
}

.toastui-editor-contents p {
    text-align: justify;
    font-size: 14px;
    margin: 0 0 15px;
    word-break: break-word;
}

.larger-viewer .toastui-editor-contents p {
    font-size: 16px; 
}

.toastui-editor-contents ul,
.toastui-editor-contents ol {
    margin: 15px 0;
    padding-left: 30px;
}

.toastui-editor-contents li p {
    font-size: 14px;
    word-break: break-word;
}

.larger-viewer .toastui-editor-contents li p {
    font-size: 16px; 
}

.toastui-editor-contents li {
    margin-bottom: 5px;
}

.toastui-editor-contents .embed {
    padding-bottom: 10px;
}

.toastui-editor-contents .embed:has(.vimeo) {
    padding-bottom: 20px;
}

.toastui-editor-contents ol {
    list-style-type: decimal;
}

.toastui-editor-contents ul {
    list-style-type: disc;
}

.toastui-editor-contents ul > li::before,
.toastui-editor-contents ol > li::before {
    display: none;
}

.toastui-editor-contents ul > li::marker,
.toastui-editor-contents ol > li::marker {
    font-size: 16px;
}

.toastui-editor-popup-body li:has(h5, h6) {
    display: none;
}

div:has(> .toastui-editor-defaultUI) {
    width: 100%;
}

.toastui-editor-toolbar-icons.last.custom-button-button,
.toastui-editor-toolbar-icons.last.media-gallery-button,
.toastui-editor-toolbar-icons.last.email-reset-button,
.toastui-editor-toolbar-icons.last.send-test-email-button {
    opacity:  0.50;
    background-position: center;
    background-size: 24px 24px;
    margin: 0;
}


.ui-form-section {
    margin: 20px 10px 0; 
    background-color: var(--primaryBackgroundColor);
    padding: 20px;
    border-radius: var(--radius);
}

.ui-form-section .date-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--mobilePadding);
}

.ui-form-section .date-fields.multiple_rows {
    grid-template-rows: auto auto auto;
}

.ui-form-section .date-fields > :nth-child(3),
.ui-form-section .date-fields > :nth-child(4) {
    grid-column: span 2;
}

.ui-form-section.slim {
    padding: 0;
    margin: 0;
}

.ui-form-section:not(.expandable){
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ui-form-section .coupon-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ui-form-section .coupon-buttons button {
    padding: 0;
}

.ui-form-section p {
    margin: 0;
}

.ui-form-section .row {
    display: grid;
    gap: 10px;
    align-items: center;
}

.ui-form-section .event-form_ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--highlightColor);
    border-radius: 5px;
    padding: 2px 8px;
}

.ui-form-section .event-form_ticket_editor,
.ui-form-section .event-form-ticket {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ui-form-section .event-form_ticket_editor .row {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--highlightColor);
}

.ui-form-section .event-form_ticket > div {
    display: flex;
    align-items: center;
    background-color: transparent;
}

.ui-form-section .frequency-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.ui-form-section .weekday-selector {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.ui-form-section .weekday-selector .ui-button {
    padding: 10px;
}

@media only screen and (min-width: 1024px) {
    .ui-form-section .row {
        grid-template-columns: 1fr 1fr;
    }

    .ui-form-section .radio-group {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .ui-form-section .weekday-selector {
        gap: 10px;
    }    
    
    .ui-form-section .weekday-selector .ui-button {
        padding: 12px 18px;
        width: 100%;
    }

    .ui-form-section .date-fields.multiple_rows {
        grid-template-rows: auto auto;
    }
    
    .ui-form-section .date-fields > :nth-child(3),
    .ui-form-section .date-fields > :nth-child(4) {
        grid-column: span 1;
    }
  }

.error-page .grid {
    grid-template-columns: 1fr;
}

.error-page .info {
  padding-left: 20px;
  padding-right: 20px;
}

.error-page .logo img {
    max-width: 320px;
    width: 100%;

    padding-top: 60px;
}

.error-page footer {
  background-color: var(--titleColor);
  color: #FFF;
}

.error-page .buttons {
  padding-top: 40px;
}

.error-page footer {
  text-align: center;
}

.error-page footer .links .ui-social-element {
  display: inline-block;
  margin-left: 10px;
}


@media screen and (min-width: 1024px) {

  .error-page .grid {
    grid-template-columns: 1fr 1fr;

    max-width: 900px;
    width: 100%;
  }

  .error-page .logo img {
    padding-top: 150px;
  }

  .error-page .error {
    padding-top: 130px;
  }

  .error-page .buttons {
    grid-template-columns: 100px 1fr;
  }

  .error-page footer {
    text-align: left;
  }

  .error-page footer .links {
    text-align: right;
  }

}

.ui-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 12px 18px;

    border: 1px solid var(--primaryButtonColor);
    border-radius: 8px;
    
    background-color: var(--primaryButtonColor);
    color: var(--buttonTextColor);
    transition: filter 0.3s;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: fit-content;
}

.ui-button.grey {
    opacity: 0.75;
}

.ui-button:hover {
    cursor: pointer;
    filter: brightness(85%);
}

.ui-button:active {
    cursor: pointer;
    filter: brightness(70%);
}

.ui-button.fullwidth {
    width: 100%;
}

.ui-button.minwidth {
    width: 160px;
}

.ui-button.highlighted {
    border-color: var(--highlightColor);
    background-color: var(--highlightColor);
    color: #FFFFFF;
}

.ui-button.secondary {
    color: var(--primaryButtonColor);
    background-color: var(--secondaryButtonColor);
    border-color: invert(var(--secondaryButtonColor));
}

.ui-button.secondary:active {
    background-color: #e2e2e2;
}

.ui-button.text {
    color: var(--primaryTextColor);
    background-color: transparent;
    box-shadow: none;
    border: none;
}

.ui-button.small{
  font-size: 12px;
  padding: 0;
  margin-bottom: 10px;
}

.ui-button.small-padded{
  padding: 8px 12px;
  font-size: 12px;
}

.ui-button.text:active {
    color: #101828;
    background-color: transparent;
    box-shadow: none;
}

.ui-button.rounded {
    border-radius: 40px;
 }

.ui-button.chip-like {
    border-radius: 40px;
    padding: 2px 8px 2px 6px;
    min-width: 125px;
    height: 32px;
    margin: 0;
    font-size: 13px;
    color: var(--highlightColor);
    border-color: var(--highlightColor);
    background-color: transparent;
    margin: 8px 0;
}

.ui-button .label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ui-button.loading,
.ui-button.disabled {
    opacity: 0.5;
    cursor: default;
    filter: brightness(100%);
}

.ui-button .spinner,
.ui-button .spinner:before {
  border-radius: 50%;
}

.ui-button .spinner {
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.ui-button .spinner:before {
  position: absolute;
  content: '';
}

.ui-button .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: white;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.ui-button.interactions {
  padding: 0;
  width: 100%;
  justify-content: flex-start;
}

.ui-button.provider-selector-button {
  width: 100%;
  height: 100%;
}

.ui-button.provider-selector-button:hover{
  filter: brightness(100%);
}
.ui-button.provider-selector-button:active {
  background-color: var(--secondaryButtonColor);
}

.ui-button.provider-selector-button.highlighted {
  outline: var(--focusOutline);
  border-color: var(--highlightColor);
  color: var(--highlightColor);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px var(--highlightColor);
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1024px) {
  .ui-button.interactions {
    width: 125px;
  }
}
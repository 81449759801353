.ui-image {
    background-color: lightgray;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.ui-image.rounded {
    border-radius: 50%;
}

.ui-image.border {
    border: 7px solid var(--primaryBackgroundColor);
}
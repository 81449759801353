.drag-drop-container {
    display: flex;
    width: 100%;
    gap: 16px;
}

.drag-drop-list {
    width: 100%;
    background-color: white;
    padding: 16px 20px 20px 20px;
    border-radius: 5px;
    border: 1px solid #fdecef;
}

.draggable-item {
    padding: 16px 20px 16px 20px;
    border-radius: 5px;
    min-height: 180px;
}

.draggable-item.dragging-over {
    background: radial-gradient(rgba(253, 236, 239, 0.8), rgba(253, 236, 239, 0.4), white);
    transition: background 0.4s ease;
}

.draggable-item-content {
    display: flex;
    justify-content: space-between;
    border: 1px solid #F75201;
    border-radius: 5px;
    padding: 8px 8px 8px 16px;
    margin: 8px 0px;
    background-color: white;
    width: 100%;
}

.draggable-item-content.dragging {
    background-color: rgb(253, 236, 239);
    transition: background-color 0.4s ease;
}
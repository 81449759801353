.events-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.events-content .tags {
  color: var(--primaryTextColor);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-color: red !important;
}

.events-content .button-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: center;
}

.events-content .grid {
    grid-template-columns: 1fr;
    gap: 18px;
}

.events-content .button-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.events-content .button-content.centered {
    justify-content: center;
}

.events-content .button-content > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.events-content .event-header .tickets {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0px;
}

.events-content .event-header .ticket-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 

    width: 100%;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 6px;
    border: 1px solid var(--borderColor);
    background-color: var(--secondaryBackgroundColor);
}

.events-content .event-header .ticket-card .left-container {
    grid-column: span 2;
    grid-template-columns: auto;
    display: grid;
    gap: 8px;
    margin: 16px 0px;
}


@media screen and (min-width: 1024px) {
  
    .events-content .grid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;
    }
    
}

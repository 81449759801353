.memberships-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.memberships-content .grid {
    grid-template-columns: 1fr;
    gap: 18px;
}

.memberships-content .frequency-switch {
    display: grid;
    padding: 6px;
    gap: 8px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 40px;
}

.memberships-content-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.stripe-payment-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.stripe-payment-form .footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.stripe-payment-form .footer .button-group {
    display: flex;
    gap: 8px;
}

.stripe-payment-form .summary,
.amount-modal .summary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 16px 0;
}

.stripe-payment-form .summary-row,
.amount-modal .summary-row {
    display: flex;
    justify-content: space-between;  
}

.stripe-payment-form .summary-column,
.amount-modal .summary-column {
    display: flex;
    flex-direction: column;
}

.stripe-payment-form .summary-row.gap,
.amount-modal .summary-row.gap {
    gap: 8px;
}

.new-ticket-modal .content {
    padding: 16px;
}

.new-ticket-modal .amount-selector,
.amount-modal .amount-selector {
    border-top: 1px solid var(--primaryTextColor);
    border-bottom: 1px solid var(--primaryTextColor);
    color: var(--primaryTextColor);
    grid-template-columns: 20% 60% 20%;
    display: grid;
    gap: 0px 0px;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.new-ticket-modal .amount-selector {
  margin-bottom: 0px;
}

.new-ticket-modal .amount-selector .button,
.amount-modal .amount-selector .button {
    border-left: 1px solid var(--primaryTextColor);
    border-right: 1px solid var(--primaryTextColor);
}
 
.new-ticket-modal .amount-selector .MuiAmountIconButton:not(.Mui-disabled),
.amount-modal .amount-selector .MuiAmountIconButton:not(.Mui-disabled) {
    color: var(--primaryTextColor) !important;
}

.ticket-details,
.payment-processed,
.payment-processed .button-group,
.ticket-details .button-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.connect-modal .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media screen and (min-width: 1024px) {
  
    .memberships-content .grid {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 18px;
    }

    .payment-processed .button-group {
        flex-direction: row;
    }
}


.org-elements-list,
.org-products-list {
    padding: var(--mobilePadding);
}

.draggable-perk,
.org-elements-list .ui-page-element {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    background-color: #ffff;
    flex-direction: column;
    text-align: center;
}
.org-elements-list .button-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr, 1fr;
    gap: 10px;
}

.org-elements-list .button-group button:only-child {
    grid-column: span 2;
}

.draggable-perk.unavailable {
    background-color: #f5f5f5;
}

.draggable-perk > div:first-child {
    margin: 8px 0px;
}

.org-elements-list .ui-page-element.title {
    margin: 0px;
}

.org-elements-list .ui-page-element.calendar {
  margin: 0px 20px;
}

.draggable-perk .active-offers-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.dragging > div,
.event-form_ticket.dragging {
    background-color: rgb(253, 236, 239) !important;
    transition: background-color 0.4s ease;
}

.dragging-over {
    background: radial-gradient(
        white,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.4),
        rgba(253, 236, 239, 0.2)
    );
    transition: background 0.4s ease;
}

@media only screen and (min-width: 1024px) {
    
    .draggable-perk,
    .org-elements-list .ui-page-element {
        flex-direction: row;
        text-align: left;
    }

    .org-elements-list .button-group {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
    }

    .org-elements-list,
    .org-products-list {
        padding: 0px;
    }

    .draggable-perk .active-offers-buttons{
        justify-content: flex-end;
    }

}

::selection {
  background: var(--highlightColor);
  color: #FFF;
}

@media only screen and (min-width: 1024px) {
  .profile {
    overflow: hidden;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .profile::-webkit-scrollbar {
    display: none;
  }
}

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    width: 100%;
    padding-bottom: 12px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: space-between;
}

.sidebar::-webkit-scrollbar {
    display: none;
}


.sidebar .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    width: 100%;
    max-width: calc(100% - 40px);
}

.sidebar .logo {
    color: var(--primaryTextColor);
    display: none;
}

@media only screen and (min-width: 1024px) {
    .sidebar {
        max-width: 369px;
        border-right: 1px solid var(--borderColor);
        height: 100vh;
        overflow: auto;
    }

    .sidebar .logo {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }

    .sidebar .logo p {
        font-size: smaller;
        margin: 0;
    }

    .sidebar .logo img {
        max-height: 12px;
    }
}

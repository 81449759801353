.profile-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    background-color: var(--primaryBackgroundColor);
}

.profile-navbar .mobile-logo {
    display: block;
    height: 40px;
    width: 40px;
}

.profile-navbar .logo {
    display: none;
    height: 32px;
}

.points{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.profile-navbar .menu-button {
    color: var(--primaryTextColor);
}

.profile-navbar .menu-button:hover {
    filter: brightness(85%);
}
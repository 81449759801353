.chip-container.padded {
    padding: 8px 0px;
}

.chip-container .chip {
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
}

.chip-container .chip.success {
    border: 1px solid #027A48;
    color: #027A48;
    background-color: #ECFDF3;
}

.chip-container .chip.warning {
    border: 1px solid #C03D29;
    color: #C03D29;
    background-color: #FFF0E9;
}

.chip-container .chip.error {
    border: 1px solid #B71C1C;
    color: #B71C1C;
    background-color: #FFEBEE;
}

.chip-container .chip.minwidth {
    width: 125px;
    text-align: start !important;
}

.chip-container .chip.gray {
    background-color: rgb(238, 242, 248);
    border: 1px solid black;
    color: black;
}
.ui-alert {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffff;
    padding: var(--mobilePadding);
    font-size: small;
    overflow: hidden;
  }

.ui-alert .content {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

.ui-alert.slim, 
.ui-alert.slim .content {
    padding: 0;
  }

.ui-alert p {
    margin: 0;
  }

.ui-alert .actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

@media only screen and (min-width: 1024px) {
  .ui-alert {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
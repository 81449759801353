.profile-preview {
    display: none;
}

.browser-skeleton {
    background-color: silver;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0 20px;
    text-align: left;
}

.browser-skeleton .url-bar {
    background-color: white;
    margin: 10px 0;
    padding: 5px 10px;
    width: calc(100% - 60px);
    display: inline-block;
    border-radius: 14px;
    position: relative;
}

.url-bar em.preview {
    text-transform: uppercase;
    background-color: #ffebee;
    padding: 2px 8px;
    border-radius: 12px;
    font-style: normal;
    font-size: 12px;
    margin-left: auto;
    color: #d32f2f;
    position: absolute;
    right: 5px;
}

.browser-skeleton span {
    background: #fe5e57;
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 10px;
    margin-right: 6px;
    display: inline-block;
}

.browser-skeleton span:nth-child(2) {
    background: #febc2e;
}

.browser-skeleton span:nth-child(3) {
    background: #27c841;
}

.organization-preview h1.ui-title {
    margin: 10px;
}

.organization-preview p.ui-description.ui-secondary-text {margin-bottom: 10px;}

@media only screen and (min-width: 1024px) {
    .profile-preview {
        display: block;
        border: 1px solid silver;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-align: center;
        height: fit-content;
        padding-bottom: 40px
    }

    .profile-preview .ui-description {
        text-align: center;
        font-size: 14px;
        line-height: 1.4em;
        white-space: pre-line;
    }

    .profile-preview > button {
        margin: 20px auto 20px;
    }
}

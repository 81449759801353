.ui-card {
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    max-width: calc(100vw - 20px);
}

.ui-card .grid {
    display: flex;
    flex-direction: column;
}

.ui-card .status-bar {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    align-items: center;
    background-color: var(--secondaryBackgroundColor);
    border-radius: 8px 8px 0 0;
}

.ui-card .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background-color: var(--primaryBackgroundColor);
    border-radius: 0 0 8px 8px;
    height: 100%;
    word-break: break-word;
}

.ui-card .content .description > div,
.ui-card .content .description iframe {
  width: 100%;
  border: none;
  outline: none;
}

.ui-card .content.even-border-radius {
    border-radius: 8px;
}

.ui-card > .content > .image {
    max-width: 100%;
    border-radius: 8px;
}

.ui-card .image.cropped {
    max-height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
}

.ui-card .button-with-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4px;
}

.ui-card .event-header,
.ui-card .perk-header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.ui-card .event-header {
    gap: 8px;
}

.ui-card .event-header .dates-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ui-card .event-header .dates-container .date {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ui-card .event-header .sub-header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 20px;
    width: 100%;
}

.ui-card .event-header .sub-header > div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
    width: 100%;
}

.ui-card .event-header .sub-header img {
    max-width: 100%;
    max-height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 8px;
}

.ui-card .event-header .calendar,
.ui-card .event-header .location {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.ui-card .event-header .location .icon {
    display: flex;
    height: 60px;
    min-width: 60px;  
    padding: 8px 8px;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    border: 1px solid var(--borderColor);
    background-color: var(--secondaryBackgroundColor);
}

.ui-card .event-header .sessions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 8px 0px;
}

.ui-card .event-header .session-selector {
    display: flex;
    align-items: baseline;
    gap: 8px;
    width: 100%;
    overflow: auto;
    padding: 2px 0px;
}

.ui-card .event-header .session-selector .ui-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    min-width: 125px;
}

.ui-card .event-header .session-selector .ui-button.selected {
    border-color: var(--highlightColor);
}


.ui-card .event-header .session-selector .ui-button .day {
    background-color: rgb(238, 237, 237);
    padding: 8px 12px;
    border-radius: 50%;
}

.ui-card .event-header .session-selector .ui-button .day.selected {
    background-color: var(--highlightColor);
    color: white;
}

.ui-card .offer-card-settings {
    width: 100%;
}

.ui-card .offer-card-settings,
.ui-card .offer-card-settings > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.ui-card .details,
.ui-card .description {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.ui-card .description img {
    max-width: 100%;
}

.ui-card .description {
    align-items: start;
}

.ui-card .status-bar .details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.ui-card .points-chip {
    display: flex;
    align-items: center;
    gap: 4px;
}

.ui-card .points-chip p {
    margin: 0;
    font-size: 14px;
    color: var(--primaryTextColor);
}

.ui-card .event-header .application-fee-disclaimer {
    text-align: center;
}

@media screen and (min-width: 1024px) {

    .ui-card.fullwidth {
        max-width: min(calc(100vw - 420px), 920px);
    }
    
    .ui-card .event-header .sub-header {
        flex-direction: row;
        align-items: center;
    }

    .ui-card .event-header .sub-header img {
        width: 200px;
        height: 200px;
    }
}
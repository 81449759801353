:root {
  /* GLOBAL PROPERTIES */
  --fontFamily: 'Inter', sans-serif;
  --highlightColor: #F75201; 
  --primaryTextColor: #101828;
  --secondaryTextColor: #667085;
  
  /* BUTTON COLORS */
  --primaryButtonColor: #000000;
  --secondaryButtonColor: #FFFFFF;
  --buttonTextColor: #FFFFFF;

  /* BACKGROUND/CARDS COLORS */
  --primaryBackgroundColor: #FFFFFF;
  --secondaryBackgroundColor: #F4F4F4;
  --borderColor: #DADADA;

  /* STRIPE COLORS */
  --stripeTheme: stripe;

  /* OTHER CONSTANTS */
  --mobilePadding: 10px;
  --orange: #F75201;
  --gap:  30px;
  --radius: 10px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
	background-color: #fdecef; /* loading screen + backoffice background */
  font-family: var(--fontFamily);
}

.main-sidebar {
  display: none;
}

.grid {
  display: grid;
  grid-gap: 30px;
  margin: 0 auto;
}

.circular-progress {
  color: var(--orange) !important;
}

.card {
  background-color: #ffffff;
  border-radius: var(--radius);
  padding: 30px; /* mudança no padding aqui */
  position: relative;
}

.card p {
  text-align: center;
  color: #1a1d1f;
}

.card .title {
    font-size: 20px;
    font-weight: bold;
    line-height: auto;
}

.action-card {
    text-align: center;
    background-color: #ebebeb;
    color: #8f8f8f;
    margin-top: 30px;
}

.action-card-description {
display: none;
}

.action-card:hover .action-card-description  {
display: block;
}

.action-card:hover {
  background-color: #dbdbdb;
  transition: 0.5s;
  transform: 0.2s translateY(-3px);
  box-shadow: 0 10px 20px  rgba(0, 0, 0, 0.2);
}


a .action-card p {
  font-size: 14px;
  color: #8f8f8f;
}

.badges-grid,
.orders-grid,
.tickets-grid {
  padding: var(--mobilePadding);
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabpanel-menu {
  margin-top: 50px;
  margin-bottom: 25px;
}

.tabpanel-menu .MuiTab-root {
  color: var(--primaryTextColor);
}

.tabpanel-menu .MuiTab-root.Mui-selected {
  color: var(--highlightColor) !important;
}

.tabpanel-menu .MuiTabs-indicator {
  background-color: var(--highlightColor) !important;
}

.tabpanel-menu.slim {
  margin-top: 0px;
}

.tabpanel {
  padding-bottom: 100px ;
}

.data-table-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 10px 0;
  gap: 10px;
}

/* LAYOUT */

.drawer-logo {
  min-height: 100px !important;
}

.drawer-logo img {
  width: 100%;
}

main {
  margin: 0 auto;
  max-width: 100vw;
  flex-grow: 1;
}

main .content {
  position: relative;
}

.back-wrapper {
  display: none;
  position: absolute;
  top: -15px;
  left: -70px;
}

.back-wrapper button {
  background-color: #fff;
}

.container-menu-switcher {
  width: 100%;
  display: flex;
  margin: auto;
  margin-bottom: 0;
}

.switcher-menu-item {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.switcher-menu-item .organization-name {
  color: #1a1d1f;
  padding: 5px;
  margin: 0px;
  display: flex;
  align-items: center;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}

.switcher-menu-item .organization-name.selected {
  color: var(--orange);
  font-weight: bold;
}

.menu-name {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
}

.menu-email {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  color: #8F8F8F;
}

.container-menu {
  display: flex;
  position: relative;
  flex-direction: column;

}

a.preview-profile-page {
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-top: 20px;
}

/* MENU */

.MuiDrawer-root button.MuiButtonBase-root {
  border-radius: unset;
}
.MuiDrawer-root button.MuiButtonBase-root.selected {
  background-color: #dfdee2;
}

.MuiDrawer-root button.MuiButtonBase-root a {
  height: 24px;
  color: var(--orange);
}

/* ELEMENTS */

.page-actions {
  text-align: right;
}

.settings-menu-button.MuiButton-contained {
  min-width: 46px;
  background-color: #b3b3b3 !important;
}

.settings-menu-button .MuiButton-endIcon {
  margin: 0;
}

.container-preview {
  margin-top: 20px;
  display: flex;
  position: relative;
  left: -15px;
}

/* DASHBOARD */

.page-top {
  max-width: 100vw;
  padding: var(--mobilePadding);
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px;
}

.page-top .page-label {
  opacity: 0.6;
  font-size: 14px;
  color: #333333;
}

.page-top .page-description {
  font-size: 16px;
  color: #8f8f8f;
}
.page-top .page-name {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
}

.tokens-overview {
    text-align: left;
}

.tokens-overview .text-1 {
  font-size: 14px;
  color: #b2b2b2;
}
.tokens-overview .text-2 {
  font-size: 24px;
  color: #29292b;
    margin: 10px 0;
}
.tokens-overview .text-3 {
  font-size: 12px;
  color: #333333;
}

.organization-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.organization-form .ui-form-section{
  margin: 0;
}

.organization-form .color-picker {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.organization-form .highlight-color-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.organization-form .button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.column {
  flex-direction: column;
}

.new-reward-wrapper .metadata-title {
  margin:  40px 0 40px 0;
}
.new-reward-wrapper .metadata-description {
  font-size: 16px;
  color: #8f8f8f;
  display: flex;
  position: relative;
  width: 100%;
  top: -25px;
}

.new-reward-wrapper .metadata-new-button,
.advanced-settings .metadata-new-button {
  float: right;
  top: -48px;
}

.new-reward-wrapper .property-row {
  margin-top: 10px;
  display: flex;
  width: 100%;
  padding-bottom: 20px;
  gap: 10px;
}

.property-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*REWARD PREVIEW*/

.reward-preview {
  width: 50%;
  background-color: #ffff;
  height: 80%;
  padding: 10px;
}

/* REWARD MINT */
.reward-preview.grid {
  padding-top:  60px;
}

.mint-form-wrapper {
  margin-top: 80px;
}

.mint-form-wrapper > *{
  grid-column: span 4;
}

.mint-form-wrapper .MuiDataGrid-root {
    min-height: 250px;
}

.mint-form-wrapper p {
    margin-bottom: 40px;
}

.mint-form-wrapper .buttons {
    margin: 30px 0 100px;
}

.mint-form-wrapper p.loading {
  margin: 40px 0;
}

/* Actvity */
.MuiDataGrid-main {
    min-height: 400px;
    background-color: #fff;
}


.new-form-field-footer {
  margin: 10px 0px;
  /* margin-block-end: 20px; */
  display: flex;
  gap: 15px;
  padding: var(--mobilePadding);
}

.new-form-field-footer.wrap {
  flex-wrap: wrap;
  justify-content: center;
}

.course-switcher-main-container {
  display: flex;
  flex-direction: column;
}

.course-switcher-main-container > p {
  margin: 2px 0 2px 0;
  font-size: smaller;
  color: gray;
}

.course-switcher-inner-container {
  display: flex;
  align-items: center;
  gap: 2vw;
  width: max-content;
}

.course-switcher-inner-container > div {
  display: flex;
}

.course-switcher-inner-container > h2 {
  font-weight: 500;
  margin: 0;
  min-width: 225px;
 }

.menu-link {
  display: flex;
  align-items: center;
  gap: 20%;
  color: #e55e29;
}

/*social-share*/

.zero{
  margin: 0;
  padding: 0;
}
.social-share-container{
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 2vh;
 padding: 15px;
}

.container-img-share {
  padding: 0 40px;
}

.container-img-share img {
  width: 100%;
}

.container-share-icons {
  margin: 30px 0 20px;
  text-align: center;
}

.social-share-secondary-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 0;
}
.social-share-horizontal-container{
  display: flex;
  align-items: center;
  gap: 10px;
}

.csv-preview {
  border: 1px solid #F75201;
  border-radius: 5px;
  display: flex;
  position: relative;
  justify-content: space-evenly;
  margin-top: 5px;
}

.key-preview {
  font-weight: bold;
}

.label-message {
  color: #68707b;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
  justify-content: center;
  top: 5px;
}

.message {
  display: flex;
  position: relative;
  justify-content: center;
  top: 15px
}

.orange {
  color: var(--orange) !important;
}

.center {
  text-align: center;
}

.center-absolutely {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-image {
  height: 80%;
  max-height: 20px;
}

.student-profile-footer {
  background-color: #d8d4d4;
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 100px;
  padding: 25px;
}

.student-profile-footer > p {
  margin: 0;
}

.student-profile-footer > a {
  color: #13171a;
  font-weight: 800;
}

.italized{
  font-style: italic;
}

.green {
  color: green;
}

.break {
  word-break: break-all;
}

.blur {
  filter: blur(15px);
  opacity: 0.6;
}

.bold {
  font-weight: 600;
}

.backdrop-buttons-container {
  position: fixed;
  top: 0;
  right: 28px;
}

.backdrop-buttons-container > * {
  color: black !important;
}

.theme-preview-container{
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.theme-preview-small-container:last-child{
  border: solid 1px black;
  border-left: none;
}

.theme-preview-small-container:first-child{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px black;
}

.evenly-spaced {
  width: 50%;
}

.theme-form-row{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.theme-form-row > .inner{
  gap: 2px;
}

.theme-form-upload-buttons {
  width: 100%;
  display: flex;
  margin: 10px 0px;
}

.confirm-modal-header {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.confirm-modal-children-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.payment-provider-modal-container {
  display: grid;
  grid-auto-rows: 1fr;
}

.payment-provider-modal-logo {
  max-width: 50%;
  padding-bottom: 20px;
  align-self: center;
}

.payment-method-button {
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 0;
}

.payment-method-button-icon-container {
  flex-basis: 25%;

  display: flex;
  justify-content: center;
}

.payment-method-button-icon {
  max-height: 44px;
  max-width: 75%;
}

.payment-method-button-label-container {
  flex-basis: 50%;
  text-align: start;
}

.payment-method-button-label {
  color: black;
  text-transform: none;
  font-size: large;
}

.payment-method-button-chevron-container {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
}

.payment-method-mbway-app,
.payment-method-bizum-app {
  padding: 20px;
  background-color: var(--primaryBackgroundColor);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.payment-method-bizum-app .first-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.payment-method-mbway-app .image,
.payment-method-bizum-app .image {
  max-width: 100px;
  border-radius: 50%;
}

.utrust-payment-button-group {
  display: flex;
  gap: 20px;
}

.desktop-manual-subscriptions-stepper {
  display: none !important;
  margin-bottom: 20px;
}

#googlepay-container {
  display: flex;
  justify-content: center;
}

.mobile-manual-subscriptions-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
}

.mobile-manual-subscriptions-stepper .progress {
  flex-basis: 33.33%;
}

.mobile-manual-subscriptions-stepper .label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mobile-manual-subscriptions-stepper .label > * {
  margin: 0px;
}

.mobile-manual-subscriptions-stepper .label > p {
  font-size: small;
  color: var(--orange);
}

.status-modal-login-button {
  text-align: center;
  margin: 15px;
}


.filter-show-all-content-container {
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.internal_offer_selector-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.offer-card-background-color-theme{
  background-color: #ffff !important
}

.frequency-switcher {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.organization-page-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.attachment-chip {
  width: 100%;
  border-radius: 5px !important;
  justify-content: space-between !important;
}

.stripe-connect-form .alert {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.stripe-connect-form .alert img {
  max-width: 100%;
}

.community-tab-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 25px;
  gap: 20px;
  align-items: center;
  padding: var(--mobilePadding);
}

.tickets-tab-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 20px;
  align-items: center;
  padding: var(--mobilePadding);
  width: 100%;
}

.points-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  margin-bottom: 25px;
  background-color: #ffffff;
}

.email-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: #ffffff;
  align-items: center;
  border-radius: 5px;
}

.email-viewer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.email-viewer .ui-description,
.email-viewer .content {
  border-radius: 5px;
  padding: 20px;
  background-color: #fdecef;
}

.email-viewer .content {
  height: 200px;
  overflow-y: scroll;
}

.points-editor .input{
  display: flex;
  gap: 20px;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.alternative-currency-icon {
  color: grey;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.onboarding-checklist {
  margin-top: 60px;
}

.onboarding-checklist .item {
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
  padding: 10px;
  background-color: #ffff;
}

.onboarding-checklist .item .actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  grid-column: span 2;
}

.tags-container {
  display: flex;
  width: 100%;
  gap: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.edit-organization-form .button-group {
  display: flex;
  gap: 16px;
}

.order-details-modal-content,
.checkin-modal-content {
  font-size: 19px;
}

.order-details-modal-content div,
.checkin-modal-content div {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 1;
}

@media only screen and (min-width: 1024px) {
  .navbar,
  .mobile-manual-subscriptions-stepper {
    display: none !important;
  }

  .desktop-manual-subscriptions-stepper {
    display: flex !important;
  }

  main {
    width: 100%;
    max-width: min(960px, calc(100% - 420px));
    /* 260 sidebar + 2x 80px main padding */
  }

  .main-sidebar {
    display: block;
  }

  .page-top {
    max-width: 100%;
  }

  .page-top,
  .badges-grid {
    padding: 0px;
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }

  .back-wrapper {
    display: block;
  }

  .organization-form-and-preview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 36px;
  }

  .community-tab-header {
    grid-template-columns: repeat(4, 1fr);
  }

  .data-table-header {
    flex-direction: row;
    justify-content: end;
    flex-wrap: wrap;
  }

  .new-form-field-footer.wrap {
    flex-wrap: nowrap;
    justify-content: start;
  }

  .tickets-tab-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Import Bootstrap and its default variables */
@import '~normalize.css/normalize.css';
@import './mui-override.css';
